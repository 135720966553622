import Logo from '../svg/logo.svg';

const IndexPage = () => (
  <div className="w-screen h-screen flex justify-center items-center bg-gray-900">
    <div>
      <Logo className="block h-64 fill-current text-white" />
      <p className="uppercase tracking-widest text-gray-600 font-black text-2xl mt-12 text-center">
        Coming Soon
      </p>
    </div>
  </div>
);

export default IndexPage;
